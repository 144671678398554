import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import Layout from '../components/Layout'
import { trackEvent } from '../utils/segmentTrack'
import Text from '../components/common/Text'
import Space from '../components/common/Space'
import { calendlyAPIKey } from '../../config/keys'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  margin-top: 80px;

  @media (max-width: 800px) {
    margin-top: 40px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  min-height: 40vh;
  height: 100%;
  flex-direction: column;
  padding-bottom: 90px;
  justify-content: center;
`

const Header = styled.div`
  padding: 0px 80px;
  padding-bottom: 32px;

  @media (max-width: 800px) {
    padding: 0px 0px;
    padding-bottom: 48px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;

  p {
    max-width: 700px;

    @media (max-width: 800px) {
      margin-bottom: 32px;
    }
  }
`

const PartnerMeeting = () => {
  const [location, setLocation] = useState(null)

  let calendlyID

  if (location === 'national') {
    calendlyID = 'partner-team-cam-east/schedule-a-meeting-with-the-audioeye-partner-team'
  }
  if (location === 'international') {
    calendlyID = 'partners-international/schedule-a-30-minute-meeting'
  }

  useEffect(() => {
    const usrLang = navigator.languages
    if (usrLang) {
      // eslint-disable-next-line no-unused-expressions
      usrLang.includes('en-US') ? setLocation('national') : setLocation('international')
    } else {
      setLocation('national')
    }
  }, [])

  const calendlyUrl = `https://calendly.com/${calendlyID}`

  if (typeof window !== 'undefined') {
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)
  }

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0
  }

  const fetchInvitee = async url => {
    try {
      const inviteeResponse = await fetch(url, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${calendlyAPIKey}`,
        },
      })
      return inviteeResponse
    } catch (err) {
      trackEvent(`API Exception`, {
        label: `Calendly tracking general error`,
        full_exception: err,
      })
      return false
    }
  }

  useEffect(() => {
    window.addEventListener('message', async e => {
      if (isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
          const rawInviteeData = await fetchInvitee(e.data.payload.invitee.uri)
          if (!rawInviteeData.ok) {
            trackEvent(`API Exception`, {
              label: `Calendly tracking general error`,
              full_exception: rawInviteeData,
            })
          }

          const inviteeData = await rawInviteeData.json()
          const companyName = inviteeData.resource.questions_and_answers.find(obj => obj.question === 'Company')
          trackEvent(`Calendly Meeting Scheduled`, {
            name: inviteeData.resource.name || '',
            email: inviteeData.resource.email || '',
            company_name: companyName.answer || '',
            calendly_url: calendlyID || '',
          })
        }
      }
    })
  }, [])

  return (
    <Layout showHeader showCTA>
      <Wrapper>
        <Header>
          <Text heading3 role="heading" aria-level="1" center>
            Find some time with one of our Partner Managers to learn more about the benefits of the AudioEye Partner
            Network
          </Text>
        </Header>
        <ContentWrapper>
          <FormWrapper>
            <Space height={16} />
            <div className="calendly-inline-widget" data-url={calendlyUrl} style={{ width: '100%', height: 750 }} />
          </FormWrapper>
        </ContentWrapper>
      </Wrapper>
    </Layout>
  )
}

export default PartnerMeeting
